import { get, has, upperFirst } from 'lodash';
const KEY_BASE = 'heroes[0].media.';

enum HeroContent {
    ImageWithVideo,
    ImageOnly,
    VideoAutoplay,
    NotSet
}

function dataHasValueAtKey(data: object, key: string, isMobile: boolean): boolean {
  const keyComplete = getFullKey(key, isMobile);

  return has(data, keyComplete);
}

function getFullKey(key: string, isMobile: boolean): string {
    return KEY_BASE + (isMobile ? convertKeyToMobileVersion(key) : key);
}

function getDefaultImageUrl(data, isMobile): string {
    const key = getFullKey('defaultImage', isMobile);
    const val = get(data, key, '');

    // val.sections.source adds support for legacy wdw/dlr media
    return val.url || (val.sections && val.sections.source) || '';
}

function convertKeyToMobileVersion(key: string): string {
    return 'mobile' + upperFirst(key);
}

function getVideoMainSources(data, isMobile): string[] {
    const key = getFullKey('videoMain', isMobile);
    const val = get(data, key, false);
    const sources = [];

    if (val.sources) {
        sources[0] = val.sources[0].url;
        sources[1] = val.sources[1].url;
    } else if (val.sections) { // val.sections.sourceN adds support for legacy wdw/dlr media
        sources[0] = val.sections.source1;
        sources[1] = val.sections.source2;
    }

    return sources;
}
function getVideoDetailSources(data, isMobile): string[] {
    const key = getFullKey('videoDetail', isMobile);
    const val = get(data, key, false);
    const sources = [];

    if (val.sources) {
        sources[0] = val.sources[0].url;
        sources[1] = val.sources[1].url;
    } else if (val.sections) { // val.sections.sourceN adds support for legacy wdw/dlr media
        sources[0] = val.sections.source1;
        sources[1] = val.sections.source2;
    }

    return sources;
}

function getDescriptiveTranscriptUrl(data, isMobile) {
    const key = getFullKey('videoDetail', isMobile);
    const val = get(data, key, false);

    // Check for proper id
    if (val && val.assets) {
        let dtUrl = '';
        for (let i = 0; i < val.assets.length; i++) {
            if (val.assets[i].id === 'descriptiveTranscript') {
                dtUrl = val.assets[i].url;
                break;
            }
        }

        return dtUrl;
    } else {
        return '';
    }
}

function getResizeImageUrl(url) {
    const isMobile = 'ontouchstart' in window && window.screen.width < 768;
    const isValidImage = url.indexOf('.jpg') !== -1;
    const alreadyResized = url.indexOf('/resize/') !== -1;
    const version = '2';
    const compression = '75';
    let dimensions = '1280/720';
    let cdnUrl = url;

    if (isValidImage && !alreadyResized) {
        if (isMobile) {
            dimensions = window.innerWidth > 0 && window.innerWidth < 433 ?
                window.innerWidth + '' :
                '433';
            dimensions += '/433';
        }

        cdnUrl = cdnUrl.replace(
            '/dam/',
            `/resize/mwImage/${version}/${dimensions}/${compression}/dam/`
        );
    }

    return cdnUrl;
}

function getClosedCaptioningUrl(data, isMobile) {
    const key = getFullKey('videoDetail', isMobile);
    const val = get(data, key, false);

    // Check for proper id
    if (val && val.assets) {
        let ccUrl = '';
        for (let i = 0; i < val.assets.length; i++) {
            if (val.assets[i].id === 'closedCaption') {
                ccUrl = val.assets[i].url;
                break;
            }
        }

        return ccUrl;
    } else {
        return '';
    }
}

function getMediaEngineId(componentName: string, idNumber: number) {
  let mediaEngineId = `${componentName}mediaEngineInstance${idNumber}`;
  if (mediaEngineId) {
    return mediaEngineId;
  }
}

function getHeroContentMode(data): HeroContent {
    let heroContent = HeroContent.NotSet;
    if (get(data, 'heroes[0].descriptions.videoMain.sections.display', false)
        && data.heroes[0].descriptions.videoMain.sections.display.length > 1) {
        heroContent = HeroContent.ImageWithVideo;
    } else if (get(data, 'heroes[0].descriptions.defaultImage.sections.source', false)
        && data.heroes[0].descriptions.defaultImage.sections.source.length > 1) {
        heroContent = HeroContent.ImageOnly;
    } else if (get(data, 'heroes[0].descriptions', false)) {
        heroContent = HeroContent.VideoAutoplay;
    }

    return heroContent;
}

declare global {
  interface Window {
    mediaEngineId: string;
  }
}

const ME_CONFIG = {
    "callbacks": {
        "ready": () => {
          document.querySelector('body').classList.add('me-ready');
          if (typeof window.parent['initConfigOverrides'] === 'function') {
            window.parent['initConfigOverrides']();
          }
        }
    },
    "layout": [
        "slides",
        "controls",
        "tabs",
        "thumbnails"
    ],
    "tabs": {
      "initial": 1
    },
    "slides": {
        "autoplay": false,
        "customHTML": {},
        "displayTime": 5000,
        "fullscreenAPIEnabled": false,
        "fullscreenOnTouchOrClick": true,
        "loop": false
    },
    "controls": {
        "video": {
            "layout": [
                "play-pause",
                "timer",
                "volume",
                "video-volume-levels",
                "progress-bar",
                "cc",
                "dt"
            ]
        }
    },
    "thumbnails": {
        "controls": false,
        "customHTML": {},
        "maxVisible": "manual",
        "previewOnHover": false,
        "transitionTime": 300,
        "minWidth": 9000
    },
    "video": {
        "autoplay": true,
        "loop": true,
        "muted": true,
        "unmuteOnFullscreen": true
    },
    "media": []
};

export {
  ME_CONFIG,
  HeroContent,
  getClosedCaptioningUrl,
  getResizeImageUrl,
  getDescriptiveTranscriptUrl,
  getVideoDetailSources,
  getVideoMainSources,
  convertKeyToMobileVersion,
  getDefaultImageUrl,
  getFullKey,
  getMediaEngineId,
  dataHasValueAtKey,
  getHeroContentMode
};
