import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { ConfigService } from '@app/core/config.service';
import { FeaturedProductComponent } from './featured-product/featured-product.component';

const destinationTypes = ['4-destination-hero', '5-destination-hero', '6-destination-hero'] as const;
declare const window: any;
type DestinationType = typeof destinationTypes[number];

@Component({
    selector: 'pixie-stories',
    templateUrl: './stories.component.html',
    styleUrls: ['./stories.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StoriesComponent implements OnInit {
    @Input() analyticsTrackingEvent = (linkId, {}) => {}; //Only passed in for WDW, for other brands defaulting to empty function
    siteId = this.configService.getValue('siteId');
    desktopOrMobile = 'desktop';
    storyOrder;
    stories: any[];

    constructor(
        private resourceBundle: ResourceBundleService,
        private configService: ConfigService,
        private featuredProductComponent: FeaturedProductComponent,
    ) { }

    getDestinationTemplate(name: string): DestinationType | undefined {
        if (this.isDestinationTemplate(name)) {
            return name;
        }
    }

    getStoryId(generalPurposeStrings, index): string {
        return `${generalPurposeStrings.storyName}-${index}`;
    }

    isDestinationTemplate(str: string): str is DestinationType {
        return destinationTypes.includes(str as any);
    }

    ngOnInit() {
        this.resourceBundle.get('stories').then(data => {
            if (data.storyOrderingPreset) {
                this.storyOrder = data.storyOrderingPreset[this.desktopOrMobile];
            }
            this.stories = data.stories;
            // flag prerender is ready in 5 seconds
            setTimeout (() => {
                window.prerenderReady = true;
           }, 5000);
        });
    }

}
