import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { ConfigService } from '@core/config.service';
import { get } from 'lodash';
import { HeroContent, getHeroContentMode, getMediaEngineId } from "../../../../shared/utils/media-engine";

@Component({
    selector: 'pixie-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeroComponent implements OnInit {
    @Input() id: string;
    @Input() titleMobile: any;
    @Input() titleDesktop: any;
    @Input() ctasMobile: object[];
    @Input() ctasDesktop: object[];
    @Input() src: any;
    @Input() dataType: string;

    siteId: string;
    isMobile = false;
    hasDesktopBackgroundVideo = false;
    hasMobileBackgroundVideo = false;
    mediaKeyBase = 'heroes[0].media.';
    mediaKeyBaseLegacy = 'heroes[0].descriptions.';

    constructor(
        private sanitizer: DomSanitizer,
        private resourceBundle: ResourceBundleService,
        private configService: ConfigService,
    ) {
        this.siteId = this.configService.getValue('siteId').toUpperCase();
        this.isMobile = 'ontouchstart' in window && window.screen.width < 768;
    }

    ngOnInit() {
        this.ctasMobile = [
            {
                alt: '',
                analyticsLinkId: '',
                title: '',
            },
            {
                alt: '',
                analyticsLinkId: '',
                analyticsLinkIdFullscreen: '',
                title: '',
                ctaURL: '',
            },
        ];
        this.ctasDesktop = [
            {
                alt: '',
                analyticsLinkId: '',
                title: '',
            },
            {
                alt: '',
                analyticsLinkId: '',
                analyticsLinkIdFullscreen: '',
                title: '',
                ctaURL: '',
            },
        ];

        window['heroMedia'] = [];
        this.resourceBundle.get('heroes').then(data => {
            // Add data for CTAs
            this.titleMobile = data.heroes[0].descriptions.mobileVideoTitle.sections.title;
            const isWdwOrDlr = this.siteId === 'WDW' || this.siteId === 'DLR';
            const heroContent = getHeroContentMode(data);
            const showMobileVideoHkdl = get(data, 'heroes[0].media.mobileVideoDetail', false);
            const showVideoWdwDlr = heroContent === HeroContent.ImageWithVideo || heroContent === HeroContent.VideoAutoplay;
            
            if (showMobileVideoHkdl || showVideoWdwDlr)
            {
                this.hasMobileBackgroundVideo = true;
                this.ctasMobile[0] = {
                    alt: data.heroes[0].descriptions.mobileVideoPlayCTA.sections.alt,
                    analyticsLinkId: data.heroes[0].descriptions.mobileVideoPlayCTA.sections.analyticsLinkId,
                    title: data.heroes[0].descriptions.mobileVideoPlayCTA.sections.title,
                    target: get(data, 'heroes[0].descriptions.mobileVideoPlayCTA.sections.target', '_self'),
                };
            }

            this.ctasMobile[1] = {
                alt: data.heroes[0].descriptions.mobileVideoDetailsCTA.title,
                analyticsLinkId: this.siteId +
                    '_HPMP_Billboard_' +
                    data.heroes[0].descriptions.mobileVideoDetailsCTA.analyticsLinkId +
                    '_Link',
                analyticsLinkIdFullscreen: this.siteId +
                    '_HPMP_InVideo_' +
                    data.heroes[0].descriptions.mobileVideoDetailsCTA.analyticsLinkId +
                    '_Link',
                title: data.heroes[0].descriptions.mobileVideoDetailsCTA.sections.title,
                ctaURL: data.heroes[0].descriptions.mobileVideoDetailsCTA.link,
                target: get(data, 'heroes[0].destinations.mobileVideoDetailsCTA.target', '_self'),
            };

            this.titleDesktop = data.heroes[0].descriptions.videoTitle.sections.title;

            if (get(data, 'heroes[0].descriptions.videoPlayCTA.sections', false) && (isWdwOrDlr ? showVideoWdwDlr : true))  {
                this.hasDesktopBackgroundVideo = true;
                this.ctasDesktop[0] = {
                    alt: data.heroes[0].descriptions.videoPlayCTA.sections.alt,
                    analyticsLinkId: data.heroes[0].descriptions.videoPlayCTA.sections.analyticsLinkId,
                    title: data.heroes[0].descriptions.videoPlayCTA.sections.title,
                    target: get(data, 'heroes[0].descriptions.videoPlayCTA.sections.target', '_self'),
                };
            }

            let ctaDesktop = data.heroes[0].descriptions.videoDetailsCTA;
            ctaDesktop.alt = ctaDesktop.sections.alt;

            // val.sections.source adds support for legacy wdw/dlr media
            if (isWdwOrDlr) {
                ctaDesktop = data.heroes[0].descriptions.videoDetailsCTA.sections;
                ctaDesktop.link = ctaDesktop.ctaURL;
                this.mediaKeyBase = this.mediaKeyBaseLegacy;
            }

            this.ctasDesktop[1] = {
                alt: ctaDesktop.alt,
                analyticsLinkId: `${this.siteId}_HPMP_Billboard_${ctaDesktop.analyticsLinkId}_Link`,
                analyticsLinkIdFullscreen: `${this.siteId}_HPMP_InVideo_${ctaDesktop.analyticsLinkId}_Link`,
                title: ctaDesktop.title,
                target: get(data, 'heroes[0].descriptions.videoDetailsCTA.target', '_self'),
                ctaURL: ctaDesktop.link
            };
        });
    }

    enterFullscreen(event) {
        event.preventDefault();
        const appComponentMediaEngineId = getMediaEngineId('appComponent', 1);
        const appComponentMediaEngineInstance = window[appComponentMediaEngineId];
        if (!appComponentMediaEngineInstance) {
            window['initConfigOverrides']();
        }

        if (appComponentMediaEngineInstance.config.callbacks.customFullscreenEnter) {
            appComponentMediaEngineInstance.config.callbacks.customFullscreenEnter();
        }
    }
}


declare global {
  interface Window {
    MEConfig: any;
    ME:any;
  }
}